define("ember-purecloud-components/components/desktop-notification/component", ["exports", "ember-purecloud-components/utils/browser", "ember-purecloud-components/utils/emoji", "ember-purecloud-components/utils/jid-helpers", "lodash.debounce", "notifyjs", "ember-purecloud-components/utils/markdown", "ember-purecloud-components/utils/messenger", "ember-purecloud-components/components/desktop-notification/template"], function (_exports, _browser, _emoji, _jidHelpers, _lodash, _notifyjs, _markdown, _messenger, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports._NOTIFICATIONS = _exports.LocalMessenger = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var LocalMessenger = new _messenger.default({
    extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
    theme: 'ice'
  });
  _exports.LocalMessenger = LocalMessenger;
  var MESSAGE_EXPANDER_SPLIT_POINT = 250;
  var PERMANENT_NOTIFICATION_TYPES = ['error', 'not-responding-message', 'select-phone-message'];

  var _NOTIFICATIONS = Ember.A([]);

  _exports._NOTIFICATIONS = _NOTIFICATIONS;
  var DesktopNotificationComponent;
  DesktopNotificationComponent = Ember.Component.extend({
    layout: _template.default,
    intl: Ember.inject.service('intl'),
    sound: Ember.inject.service(),
    preferences: Ember.inject.service(),
    needsServices: ['chat'],
    title: ' ',
    message: '',
    rawMessage: '',
    icon: '',
    tag: '',
    type: 'info',
    hideAfter: 5,
    volume: 1,
    shouldShowPopup: true,
    shouldPlaySound: true,
    showCloseButton: true,
    fallbackToInBrowser: true,
    forceBrowserNotification: false,
    showNotificationEverywhere: false,
    markdownToHtml: true,
    meta: {},
    clickHandler: null,
    notificationOutputDeviceId: null,
    target: null,
    permission: null,
    messageStyle: null,
    // space delimited classes applied at li level
    isPopout: false,
    // For desktop: `notifyShow`, `notifyClose`, `notifyClick`, `notifyError`
    // For in-browser: handled by target's `actions` property
    actionHandlers: {},
    hide: function hide() {
      var _this = this;

      var notification = this.get('notification');

      if (notification) {
        notification.hide();
        Ember.run.later(function () {
          var $el = Ember.$(_this.get('notification.el'));

          if ($el) {
            $el.remove();
          }

          _this.destroy();
        });
      }

      if (Ember.testing) {
        _NOTIFICATIONS.removeObject(this);
      }
    },
    showNotification: function showNotification() {
      var _this2 = this;

      var preferences = this.get('preferences');
      var hasActionHandlers = Object.keys(this.get('actionHandlers')).length > 0;

      if (this.get('shouldShowPopup') && !this.get('isPopout')) {
        if (this.get('showNotificationEverywhere')) {
          if (this.get('canShowNativeChatNotification')) {
            this.showBrowserNotification();
            return this.showNativeChatNotification();
          }

          if (!_notifyjs.default.needsPermission) {
            this.showDesktopNotification();
          }

          this.showBrowserNotification();
        } else {
          if (this.get('canShowNativeChatNotification')) {
            var cmd = {
              cmd: 'getPreferences',
              prefs: ['notification_showToast', 'notification_bounceDockIcon', 'notification_flashTaskbar']
            };
            return new Ember.RSVP.Promise(function (resolve, reject) {
              try {
                var sCmd = JSON.stringify(cmd);
                window.cefQuery({
                  request: sCmd,
                  persistent: false,
                  onSuccess: function onSuccess(response) {
                    try {
                      var obj = JSON.parse(response);
                      resolve(obj);
                    } catch (e) {
                      resolve({});
                    }
                  },
                  onFailure: function onFailure(response) {
                    reject(response);
                  }
                });
              } catch (e) {
                Ember.Logger.error('DESKTOP-NOTIFICATION: Error requesting desktop promise', {
                  error: e
                });
                reject();
              }
            }).then(function () {
              var notifications = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
              var showToast = !!Ember.get(notifications, 'notification_showToast');
              var bounceDock = !!Ember.get(notifications, 'notification_bounceDockIcon');
              var flashTaskbar = !!Ember.get(notifications, 'notification_flashTaskbar');
              var shouldNotify = showToast || bounceDock || flashTaskbar;
              var showDesktopNotification = shouldNotify && !_this2.get('forceBrowserNotification') && !hasActionHandlers;

              if (showDesktopNotification) {
                _this2.showNativeChatNotification();
              }

              if (!showToast || hasActionHandlers) {
                _this2.showBrowserNotification();
              }
            });
          } else if (!_notifyjs.default.needsPermission && preferences.get('notifications.global.notify') && !hasActionHandlers) {
            this.showDesktopNotification();
          } else if (this.get('fallbackToInBrowser') && !this.get('isMobile')) {
            this.showBrowserNotification();
          }
        }
      }

      if (this._getShouldPlaySound()) {
        this.playSound();
      }
    },
    _getShouldPlaySound: function _getShouldPlaySound() {
      return this.get('shouldPlaySound');
    },
    escapeEntities: function escapeEntities(message) {
      if (typeof message !== 'string') {
        return '';
      }

      return message.replace(/(<([^>]+)>)/ig, '').replace(/&quot;/g, '"').replace(/&#39;/g, '\'').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
    },
    showNativeChatNotification: function showNativeChatNotification() {
      window._HostedContextFunctions.showNativeNotification({
        title: this.get('title'),
        message: this.escapeEntities(this.get('message')),
        icon: this.get('icon'),
        tag: this.get('tag'),
        type: this.get('type'),
        hideAfter: this.get('hideAfter'),
        mute: !this._getShouldPlaySound(),
        onclick: this.notificationClickHandler(),
        meta: this.get('meta')
      });
    },
    showDesktopNotification: function showDesktopNotification() {
      var options = {
        body: this.escapeEntities(this.get('message')),
        icon: this.get('icon'),
        tag: this.get('tag'),
        timeout: this.shouldHide() ? this.get('hideAfter') : void 0,
        notifyClick: this.notificationClickHandler()
      };
      options = Object.assign({}, options, this.get('actionHandlers'));
      var title = this.get('title') || '';
      var notification = new _notifyjs.default(title, options);
      notification.show();
    },
    shouldHide: function shouldHide() {
      // Timeout behavior is built into Safari on Mac with notification center,
      // providing a hideafter will remove the notification from notification center which is undesirable.
      return !_browser.default.isSafari && this.get('hideAfter');
    },
    setupBrowserNotificationMessage: function setupBrowserNotificationMessage(message) {
      var title = Ember.$.trim(this.get('title'));
      var emojifiedMessage = (0, _emoji.emojifyString)(message);

      if (this.get('markdownToHtml')) {
        emojifiedMessage = (0, _emoji.emojifyMarkdown)((0, _markdown.markdownToHtml)(message));
      }

      var reg = new RegExp(/(?!<code>)<a href/ig);
      emojifiedMessage = emojifiedMessage.replace(reg, '<a target="_blank" href');
      message = "<span class=\"notification-text\">".concat(emojifiedMessage, "</span>");

      if (title) {
        title = Ember.Handlebars.Utils.escapeExpression(title);
        message = "<h6>".concat(title, "</h6>").concat(message);
      }

      return message;
    },
    updateBrowserNotification: function updateBrowserNotification(newMessage) {
      var message = this.setupBrowserNotificationMessage(newMessage);
      this.get('notification').update({
        message: message
      });
    },
    showBrowserNotification: function showBrowserNotification() {
      var _this3 = this;

      var message = this.setupBrowserNotificationMessage(this.get('rawMessage') || this.get('message'));
      var type = this.get('type');
      var tag = this.get('tag');
      var useExpander = message.length > MESSAGE_EXPANDER_SPLIT_POINT;
      var hideAfter = this.get('hideAfter');

      if (PERMANENT_NOTIFICATION_TYPES.includes(type)) {
        hideAfter = false;
      } else if (this.get('preferences.test.longNotifications')) {
        hideAfter = 20;
      }

      var options = {
        message: message,
        type: this.get('type'),
        showCloseButton: this.get('showCloseButton'),
        hideAfter: hideAfter,
        actions: this.getBrowserActions(),
        id: tag || void 0,
        events: {
          click: this.notificationClickHandler(),
          'click .messenger-close': function clickMessengerClose(e) {
            // Don't trigger the notificationClickHandler when the close button is clicked
            e.stopPropagation();
          },
          'click .notification-text .read-more': function clickNotificationTextReadMore(e) {
            e.stopPropagation();
          },
          'click .notification-text .read-less': function clickNotificationTextReadLess(e) {
            e.stopPropagation();
          }
        }
      };
      var notification = LocalMessenger.post(options);
      this.set('notification', notification);

      if (useExpander) {
        this._addExpanderBehavior(MESSAGE_EXPANDER_SPLIT_POINT);

        notification.on('hide', function () {
          Ember.run(function () {
            return _this3._removeExpanderBehavior();
          });
        });
      }

      if (Ember.testing) {
        _NOTIFICATIONS.pushObject(this);

        notification.on('hide', function () {
          return _NOTIFICATIONS.removeObject(_this3);
        });
      }

      this.addListClasses();
      this.addToastAccessibility();
    },
    _addExpanderBehavior: function _addExpanderBehavior(slicePoint) {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var _this4 = this;

        var $el = this.get('notification.$message');
        var $notificationText = $el && $el.find('.messenger-message-inner .notification-text');
        var intl = this.get('intl');

        if ($notificationText && intl) {
          this.set('notification.$notificationText', $notificationText);
          $notificationText.expander({
            slicePoint: slicePoint,
            expandText: intl.t('notification.expander.showMore').toString(),
            userCollapseText: intl.t('notification.expander.showLess').toString(),
            afterExpand: function afterExpand() {
              window.clearTimeout(_this4.get('notification._hideTimeout'));
            }
          });
        }
      });
    },
    _removeExpanderBehavior: function _removeExpanderBehavior() {
      var $notificationText = this.get('notification.$notificationText');

      if ($notificationText) {
        $notificationText.expander('destroy');
        this.set('notification.$notificationText', null);
      }
    },
    notificationClickHandler: function notificationClickHandler() {
      var _this5 = this;

      var roomJid = this.get('tag');
      var target = this.get('target');
      var isAcd = (0, _jidHelpers.isAcdJid)(roomJid);

      if (this.get('clickHandler')) {
        return function (event) {
          window.focus();

          _this5.get('clickHandler')(event);
        };
      } // TODO: Handle clicks for things not chat.  Temporary fix until a click handler is passed in.


      if (this.get('soundId') !== 'chat') {
        return function (event) {
          window.focus();
        };
      }

      return function (event) {
        Ember.run(function () {
          window.focus();

          if (Ember.$(event.target).is('button.messenger-close')) {
            return;
          }

          if (_this5.services.chat.get('connected')) {
            if (!isAcd) {
              _this5.services.chat.openRoom(roomJid);
            }

            _this5.triggerAction({
              action: 'openPanel',
              actionContext: isAcd ? ['acdInteractions'] : ['conversations'],
              target: target
            });
          }
        });
      };
    },
    playSound: (0, _lodash.default)(function () {
      var _this6 = this;

      Ember.run(function () {
        var soundId = _this6.get('soundId');

        var volume = _this6.get('volume');

        var deviceId = _this6.get('notificationOutputDeviceId');

        if (soundId) {
          _this6.get('sound').playSound(soundId, volume, deviceId);
        }
      });
    }, 500, {
      leading: true,
      trailing: false
    }),
    getBrowserActions: function getBrowserActions() {
      var _this7 = this;

      var actions = {};
      var target = this.get('target');

      if (this.get('actionHandlers')) {
        var _loop = function _loop() {
          var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
              key = _Object$entries$_i[0],
              value = _Object$entries$_i[1];

          var label = void 0;

          if (value && _typeof(value) === 'object' && value.label && value.action) {
            label = value.label;
          } else {
            label = value;
          }

          actions[key] = {
            label: label,
            action: function action() {
              if (value.action) {
                value.action(_this7);
              } else if (target.send) {
                target.send(key, _this7);
              } else if (typeof target[key] === 'function') {
                target[key](_this7);
              }
            }
          };
        };

        for (var _i = 0, _Object$entries = Object.entries(this.get('actionHandlers')); _i < _Object$entries.length; _i++) {
          _loop();
        }
      }

      return actions;
    },
    addListClasses: function addListClasses() {
      var classesToAdd = this.get('messageStyle');
      var $el = Ember.$(this.get('notification.el'));

      if (classesToAdd && $el) {
        $el.addClass(classesToAdd);
      }
    },
    addToastAccessibility: function addToastAccessibility() {
      var $el = Ember.$(this.get('notification.el'));
      $el.attr('role', 'alert');
      $el.attr('aria-live', 'polite');
      var anchorEl = $el.find('a');

      if (anchorEl) {
        for (var i = 0; i < anchorEl.length; i++) {
          if (!anchorEl[i].hasAttribute('href')) {
            anchorEl[i].setAttribute('href', '#');
          }
        }
      }

      var closeText = this.get('intl').t('notification.close');
      $el.find('.messenger-close').attr('aria-label', closeText);
    }
  });
  DesktopNotificationComponent.reopenClass({
    show: function show(options, owner) {
      var showDeprecation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      if (showDeprecation) {
        (false && !(false) && Ember.deprecate("Direct access to DesktopNotificationComponent is deprecated. Please use the notification service methods.", false, {
          id: 'directory-core.deprecate-notification-component',
          until: '5.0.0',
          url: 'https://bitbucket.org/inindca/web-directory/src/develop/DEPRECATIONS.md#markdown-header-notifications-as-component-instead-of-service'
        }));
      } // Legacy component support for tests -_-


      if (!owner.hasRegistration('component:desktop-notification')) {
        owner.register('component:desktop-notification', DesktopNotificationComponent);
      }

      if (options) {
        if (Ember.String.isHTMLSafe(options.message)) {
          options.message = options.message.toString();
        }

        if (Ember.String.isHTMLSafe(options.title)) {
          options.title = options.title.toString();
        }
      }

      Ember.setOwner(options, owner);
      var notification = owner.lookup('component:desktop-notification');
      notification.setProperties(options);
      notification.showNotification();
      return notification;
    }
  });
  var _default = DesktopNotificationComponent;
  _exports.default = _default;
});