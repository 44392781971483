define("ember-purecloud-components/utils/pigeon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.topicPriority = void 0;
  var topicPriority = {
    presence: 1,
    routingStatus: 2,
    conversationsummary: 3,
    other: 4,
    outofoffice: 5,
    geolocation: 6
  };
  _exports.topicPriority = topicPriority;
});