define("ember-purecloud-components/services/sound", ["exports", "ember-purecloud-components/mixins/logger", "howler", "lodash.merge", "lodash.partial", "ember-purecloud-components/utils/newrelic-legacy"], function (_exports, _logger, _howler, _lodash, _lodash2, _newrelicLegacy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_logger.default, {
    application: Ember.inject.service(),
    session: Ember.inject.service(),
    sounds: {},
    ringtones: Ember.A([]),
    phoneRinging: false,
    digitalRinging: false,
    digitalSoundId: 'digital',
    onAuthenticated: Ember.on('init', Ember.observer('session.isAuthenticated', function () {
      if (this.get('session.isAuthenticated')) {
        this.initSounds();
      }
    })),
    maybeLog: function maybeLog() {
      var _this$logger;

      (_this$logger = this.logger).debug.apply(_this$logger, arguments);
    },
    handleForceStopRinging: function handleForceStopRinging(self, soundId) {
      (0, _newrelicLegacy.addPageAction)('voiceRingtoneEvent', {
        action: 'forceStopRinging',
        soundId: soundId
      });
      self.maybeLog('sound:howler onplay event: Ringing should be stopped. Force stopping ringing', {
        soundId: soundId
      });
      self.stopSound(soundId);
    },
    onPlay: function onPlay(self, soundId) {
      self.maybeLog("sound:howler onplay event: sound playing", {
        source: this._src,
        soundId: soundId
      });
      var shouldHandleDigital = soundId === 'digital' && !self.get('digitalRinging');
      var shouldHandlePhone = (soundId === 'phone' || self.isRingtone(soundId)) && !self.get('phoneRinging');

      if (shouldHandleDigital || shouldHandlePhone) {
        self.handleForceStopRinging(self, soundId);
      }
    },
    onStop: function onStop(self, soundId) {
      self.maybeLog("sound:howler onstop event: Stopping Ringing", {
        soundId: soundId
      });
    },
    createSound: function createSound(id, urls) {
      var extraOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var defaultOptions = {
        src: urls,
        loop: false,
        html5: true
      };
      var options = (0, _lodash.default)({}, defaultOptions, extraOptions);
      this.get('sounds')[id] = new _howler.Howl(options);

      if (id === 'phone' || id === 'digital' || this.isRingtone(id)) {
        this.get('sounds')[id].on('play', (0, _lodash2.default)(this.onPlay, this, id));
        this.get('sounds')[id].on('stop', (0, _lodash2.default)(this.onStop, this, id));
      }

      return this.get('sounds')[id];
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.get('sounds')['phone'].off('play');
      this.get('sounds')['phone'].off('stop');
    },
    init: function init() {
      this._super.apply(this, arguments);
    },
    initSounds: function initSounds() {
      var urlPrefix = this.get('application.urlprefix');
      this.createSound('chat', [urlPrefix + 'assets/sounds/chat-notification.mp3', urlPrefix + 'assets/sounds/chat-notification.aac', urlPrefix + 'assets/sounds/chat-notification.ogg' // urlPrefix + 'assets/sounds/chat-notification.wav'
      ]);
      this.createSound('interaction', [urlPrefix + 'assets/sounds/chat-notification.mp3', urlPrefix + 'assets/sounds/chat-notification.aac', urlPrefix + 'assets/sounds/chat-notification.ogg' // urlPrefix + 'assets/sounds/chat-notification.wav'
      ]);
      this.createSound('phone', [urlPrefix + 'assets/sounds/phone-ringtone.mp3', urlPrefix + 'assets/sounds/phone-ringtone.aac', urlPrefix + 'assets/sounds/phone-ringtone.ogg' // urlPrefix + 'assets/sounds/phone-ringtone.wav'
      ], {
        loop: true
      }); // sound acquired from: https://www.freesound.org/people/rhodesmas/sounds/322895/ licensed under cc-attribution

      this.createSound('hangup', [urlPrefix + 'assets/sounds/disconnect.mp3', urlPrefix + 'assets/sounds/disconnect.aac', urlPrefix + 'assets/sounds/disconnect.ogg']); // webrtc softphone dialpad (non-dtmf)
      // sound acquire from: https://www.freesound.org/people/Gorky173/sounds/169191/ licensed uder cc-attribution

      this.createSound('softphone', [urlPrefix + 'assets/sounds/softphonedialpad.mp3', urlPrefix + 'assets/sounds/softphonedialpad.aac', urlPrefix + 'assets/sounds/softphonedialpad.ogg'], {
        volume: 0.2
      }); // Alerting sound for non call interactions

      this.createSound('digital', [urlPrefix + 'assets/sounds/digital-ringtone.mp3', urlPrefix + 'assets/sounds/digital-ringtone.aac', urlPrefix + 'assets/sounds/digital-ringtone.ogg'], {
        loop: true
      }); // Alerting sound for auto answer, same as digital with no looping

      this.createSound('digital-no-repeat', [urlPrefix + 'assets/sounds/digital-ringtone.mp3', urlPrefix + 'assets/sounds/digital-ringtone.aac', urlPrefix + 'assets/sounds/digital-ringtone.ogg']);
    },
    playSound: function playSound(soundId, volume) {
      var outputDeviceId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var sound = this.get('sounds')[soundId];
      var embeddedMute = this.get('application.isInteractionPopout') || this.get('application.isScreenRecordingPopout');

      if ((soundId === 'phone' || soundId === 'digital' || this.isRingtone(soundId)) && embeddedMute) {
        return;
      }

      this.maybeLog("sound:howler playSound", {
        soundId: soundId
      });

      if (sound && !Ember.testing) {
        sound.seek(0);

        if (volume !== undefined) {
          sound.volume(volume);
        } else {
          sound.volume(1);
        }

        if (outputDeviceId) {
          sound.setOutputDevice(outputDeviceId);
        }

        sound.play();
      }

      if (this.isRingtone(soundId) || soundId === 'phone') {
        this.set('phoneRinging', true);
      } else if (soundId === 'digital') {
        this.set('digitalRinging', true);
      }
    },
    stopSound: function stopSound(soundId) {
      this.maybeLog("sound:howler stopSound", {
        soundId: soundId
      });

      if (this.get('sounds')[soundId]) {
        this.get('sounds')[soundId].stop();

        if (this.isRingtone(soundId) || soundId === 'phone') {
          this.set('phoneRinging', false);
        } else if (soundId === 'digital') {
          this.set('digitalRinging', false);
        }
      }
    },
    isRinging: function isRinging() {
      var ringtones = this.get('ringtones');
      var sound = this.get('sounds')['phone'];
      return sound && sound.playing() || ringtones.some(function (ringtone) {
        return ringtone.options.playing();
      });
    },
    playRingtone: function playRingtone(volume, outputDeviceId) {
      var ringtoneSelectionId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'phone';
      this.playSound(ringtoneSelectionId, volume, outputDeviceId);
    },
    playDigitalRingtone: function playDigitalRingtone(volume, outputDeviceId) {
      this.playSound(this.get('digitalSoundId'), volume, outputDeviceId);
    },
    stopRingtone: function stopRingtone() {
      var soundId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'phone';
      this.stopSound(soundId);
    },
    isRingtone: function isRingtone(soundId) {
      var ringtones = this.get('ringtones');
      return ringtones.some(function (ringtone) {
        return soundId === ringtone.id;
      });
    }
  });

  _exports.default = _default;
});