define("ember-webrtc-components/components/profile-dropdown-menu/component", ["exports", "ember-webrtc-components/components/profile-dropdown-menu/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UP_KEYCODE = 38;
  var DOWN_KEYCODE = 40;
  var ESC_KEYCODE = 27;
  var SPACE_KEYCODE = 32;
  var ENTER_KEYCODE = 13;
  var TAB_KEYCODE = 9;

  var getParent = function getParent($this) {
    var selector = $this.attr('data-target');

    if (!selector) {
      selector = $this.attr('href');
      selector = selector && /#[A-Za-z]/.test(selector) && selector.replace(/.*(?=#[^\s]*$)/, ''); // strip for ie7
    }

    var $parent = selector && Ember.$(selector);
    return $parent && $parent.length ? $parent : $this.parent();
  };

  var _default = Ember.Component.extend({
    classNames: ['dropdown-menu-view'],
    classNameBindings: ['requireMediaHelper:disabled'],

    /**
     * @Attribute
     *
     * Attribute that can be optionally passed in to determine if media helper
     * is required and thus if certain elements should be disabled
     */
    requireMediaHelper: false,
    layout: _template.default,
    showMenu: false,
    actions: {
      toggleMenu: function toggleMenu(event) {
        if (this.get('requireMediaHelper')) {
          event.preventDefault();
          return;
        }

        this.toggleProperty('showMenu');
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var id = this.get('elementId');
      Ember.$(window).on("click.".concat(id), function (event) {
        Ember.run(function () {
          var target = Ember.$(event.target);
          var parents = target.parents('.dropdown-menu-view');
          var targetOutside = !parents.length || id !== parents.attr('id');
          var targetInput = target.parent('.input').length;
          var listItem = target.prop('tagName') === 'LI' || target.prop('tagName') === 'A';

          if (targetOutside || targetInput || listItem) {
            _this.set('showMenu', false);
          }
        });
      });
      Ember.$('#' + id).on("keydown.".concat(id), function (event) {
        Ember.run(function () {
          var regxp = new RegExp("".concat(ESC_KEYCODE, "|").concat(SPACE_KEYCODE, "|").concat(ENTER_KEYCODE));

          if (!regxp.test(event.which)) {
            return;
          }

          event.stopPropagation();
          event.preventDefault();
          var $this = Ember.$(event.target).closest('.dropdown-menu-view');

          if ($this.is('.disabled, :disabled')) {
            return;
          }

          var $parent = getParent($this);

          var isActive = _this.get('showMenu');

          if (!isActive && event.which !== ESC_KEYCODE || isActive && event.which === ESC_KEYCODE) {
            if (event.which === ESC_KEYCODE) {
              $parent.find('.btn-toggle').trigger('focus');
              return _this.set('showMenu', false);
            }

            return _this.set('showMenu', true);
          }

          var desc = ' li > *';
          var $items = $parent.find('.dropdown-menu' + desc);

          if (!$items.length) {
            return;
          }

          var index = $items.index(event.target);

          if (isActive && (event.which === ENTER_KEYCODE || event.which === SPACE_KEYCODE)) {
            setTimeout(function () {
              $items.eq(index).trigger('click');
            });
            $parent.find('.btn-toggle').trigger('focus');
          }
        });
      });
      Ember.$("#".concat(id)).on("keydown.".concat(id), function (event) {
        Ember.run(function () {
          // Only react to directional changes in the list
          if (![TAB_KEYCODE, UP_KEYCODE, DOWN_KEYCODE].includes(event.which)) {
            return;
          }

          var $this = Ember.$(event.target).closest('.dropdown-menu-view');

          if ($this.is('.disabled, :disabled')) {
            return;
          }

          var $parent = getParent($this);

          var isActive = _this.get('showMenu');

          var $items = $parent.find('.dropdown-menu li > *');

          if (!$items.length || !isActive) {
            return;
          }

          var index = $items.index(event.target);
          var firstIndex = 0;
          var lastIndex = $items.length - 1; // If the the dropdown menu is not already focused - don't focus trap it

          if (index === -1) {
            return;
          }

          event.stopPropagation();
          event.preventDefault();

          if (event.which === UP_KEYCODE || event.which === TAB_KEYCODE && event.shiftKey) {
            if (index === firstIndex) {
              index = lastIndex;
            } else {
              index--;
            }
          }

          if (event.which === DOWN_KEYCODE || event.which === TAB_KEYCODE && !event.shiftKey) {
            if (index === lastIndex) {
              index = firstIndex;
            } else {
              index++;
            }
          }

          $items.eq(index).trigger('focus');
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off(".".concat(this.get('elementId')));
    }
  });

  _exports.default = _default;
});