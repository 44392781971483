define("ember-purecloud-components/services/presence", ["exports", "ember-purecloud-components/mixins/logger", "ember-purecloud-components/models/presence", "ember-purecloud-components/utils/intl", "ember-purecloud-components/utils/newrelic-legacy", "ember-purecloud-components/utils/timeout-promise", "lodash.groupby", "lodash.isempty"], function (_exports, _logger, _presence, _intl, _newrelicLegacy, _timeoutPromise, _lodash, _lodash2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _mapping;

  var getPresenceMappings = function getPresenceMappings(lookup) {
    // This is a function because the lookups need to happen after the app is boostrapped.
    // Attempts made to refactor that have ended in failure: 2.5
    // last attempt date: 01/05/2015 - eric lifka
    // most recent attempt: 06/02/2015 - sam wenke
    // Ability to lookup via entity.presence, entity.presence, presence code, or guid.
    if (!_mapping) {
      _mapping = {
        available: lookup('chat.presence.available').toString(),
        online: lookup('chat.presence.available').toString(),
        busy: lookup('chat.presence.busy').toString(),
        idle: lookup('chat.presence.away').toString(),
        away: lookup('chat.presence.away').toString(),
        on_queue: lookup('chat.presence.onQueue').toString(),
        out_of_office: lookup('chat.presence.outOfOffice.title').toString(),
        offline: lookup('chat.presence.offline').toString(),
        not_responding: lookup('chat.presence.notResponding').toString(),
        meal: lookup('chat.presence.meal').toString(),
        training: lookup('chat.presence.training').toString(),
        break: lookup('chat.presence.break').toString(),
        meeting: lookup('chat.presence.meeting').toString()
      };
    }

    return _mapping;
  };

  var _default = Ember.Service.extend(_logger.default, {
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    notification: Ember.inject.service(),
    presenceDefinitions: null,
    // Dynamically injected services
    session: null,
    application: null,
    presenceSubscription: null,
    acdDivisionPresenceProfileToggle: Ember.computed.readOnly('session.features.acdDivisionPresenceProfileToggle'),
    init: function init() {
      this._super.apply(this, arguments);

      var owner = Ember.getOwner(this);
      var session = owner.lookup('service:session');
      var application = owner.lookup('service:application');
      var presenceSubscription = owner.lookup('service:presenceSubscription');

      if (!application || !session || !presenceSubscription) {
        this.logger.error('Application, session, and presence subscriptions services are required, but not found');
        return;
      }

      this.setProperties({
        session: session,
        application: application,
        presenceSubscription: presenceSubscription,
        presenceDefinitions: Ember.A([]),
        outOfBandPresenceDefinitions: Ember.A([])
      });
    },
    allPresences: Ember.computed('primaryPresences.[]', 'allSecondaryPresences', function () {
      var secondary = Object.values(this.get('allSecondaryPresences') || {}).flat();
      return [].concat(this.get('primaryPresences'), secondary);
    }),
    allActivePresences: Ember.computed('primaryPresences.[]', 'allSecondaryPresences', function () {
      var secondary = Object.values(this.get('allSecondaryPresences') || {}).flat();
      return [].concat(this.get('primaryPresences'), secondary).reject(function (presence) {
        return presence.get('deactivated');
      });
    }),
    primaryPresences: Ember.computed('presenceDefinitions.[]', function () {
      var _this = this;

      return this.get('presenceDefinitions').filter(function (presence) {
        if (presence.hasOwnProperty('type')) {
          return presence.type.toLowerCase() === 'system';
        }
      }).map(function (presence) {
        return _presence.default.create({
          presence: presence
        }, Ember.getOwner(_this).ownerInjection());
      });
    }),
    secondaryPresences: Ember.computed('allSecondaryPresences.[]', function () {
      var presences = this.get('allSecondaryPresences') || {};
      var keys = Object.keys(presences);
      keys.forEach(function (key) {
        if (presences[key]) {
          presences[key] = Ember.A(presences[key]).reject(function (presence) {
            return presence.get('deactivated');
          });
        }
      });
      return presences;
    }),
    allSecondaryPresences: Ember.computed('presenceDefinitions.[]', function () {
      var _this2 = this;

      var secondaryPresenceDefinitions = (this.get('presenceDefinitions') || []).filter(function (presence) {
        return presence.hasOwnProperty('type') && presence.type.toLowerCase() === 'user';
      }).map(function (presence) {
        return _presence.default.create({
          presence: presence
        }, Ember.getOwner(_this2).ownerInjection());
      });
      return (0, _lodash.default)(secondaryPresenceDefinitions, function (presence) {
        return presence.get('key');
      });
    }),
    loadPresences: function loadPresences() {
      var _this3 = this;

      var definitions = this.loadAllPresenceDefinitions();
      var promise = Ember.RSVP.resolve(definitions);
      promise.then(function () {
        var definitions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        _this3.set('presenceDefinitions', Ember.A(definitions));

        var definitionIds = _this3.get('presenceDefinitions').mapBy('id');

        var currentId = _this3.get('session.person.presenceDefinition.id');

        if (currentId && definitionIds.indexOf(currentId) === -1) {
          return _this3.findPresence(currentId).catch(function (error) {
            _this3.get('logger').error('Presence: Could not fetch out-of-band presence.', {
              error: error,
              remoteData: {
                currentId: currentId
              }
            });
          });
        }
      }).catch(function (error) {
        _this3.get('logger').error('Presence: Could not get presence definitions:', {
          error: error
        });

        _this3.set('presenceDefinitions', []);

        Ember.Logger.error('presenceDefinitionsFetchFailure');
        return;
      });
      return promise;
    },
    loadAllPresenceDefinitions: function loadAllPresenceDefinitions() {
      var _this4 = this;

      var deferred = Ember.RSVP.defer();
      this.loadPresenceDefinitions().then(function (data) {
        _this4.handlePresenceData(data, deferred);
      });
      var presenceTimeout = Ember.testing ? 2000 : 20000;
      return (0, _timeoutPromise.default)(deferred.promise, presenceTimeout, 'presence#loadAllPresenceDefinitions');
    },
    loadPresenceDefinitions: function loadPresenceDefinitions() {
      var url = this.get('application').pcV2Uri('api/v2/presence/definitions');
      return this.get('ajax').request(url, {
        method: 'GET',
        contentType: 'application/json; charset=UTF-8'
      });
    },
    handlePresenceData: function handlePresenceData(data, deferred) {
      var presences = data.entities.map(function (value) {
        if (value.systemPresence.toLowerCase() === 'onqueue') {
          value.systemPresence = 'ON_QUEUE';
        }

        return value;
      });
      return deferred.resolve(presences);
    },
    localizePresenceString: function localizePresenceString(presence) {
      var intl = this.get('intl');
      var lookup = intl.t.bind(intl);
      var mapping = getPresenceMappings(lookup);
      return mapping[presence] || mapping.offline;
    },
    getPresence: function getPresence(presenceString) {
      var primary = this.get('primaryPresences');
      var secondary = this.get('secondaryPresences')[presenceString.toLowerCase()];
      var collection = primary;

      if (!(0, _lodash2.default)(secondary)) {
        collection = Ember.A(secondary).sortBy('presence.createdDate');
      }

      return Ember.A(collection).find(function (mapPresence) {
        var rawPresence = mapPresence.get('presence');
        var mapHasPresence = rawPresence.id === presenceString.toString();
        var systemPresence = rawPresence.systemPresence;
        var mapHasSystemPresence = systemPresence && (systemPresence.toLowerCase() === presenceString.toLowerCase() || systemPresence.toLowerCase() === presenceString.toLowerCase().replace(/-|\s/g, '_'));

        if (mapHasPresence || mapHasSystemPresence) {
          return rawPresence;
        }
      });
    },
    getPresenceDefinition: function getPresenceDefinition(presence) {
      var presenceMap = this.get('presenceDefinitions');
      return presenceMap.find(function (mapPresence) {
        return mapPresence.id === presence.id;
      });
    },
    getPresenceFallback: function getPresenceFallback(presence) {
      if (presence) {
        return presence.toLowerCase().replace(/-|\s/g, '_');
      }
    },
    localizePresence: function localizePresence(presence, fallback) {
      if (typeof fallback === 'string' && fallback.toLowerCase() === 'out_of_office') {
        return this.localizePresenceString(fallback);
      }

      if (typeof presence === 'string' && !fallback) {
        return this.localizePresenceString(presence);
      }

      if (presence) {
        if (_typeof(presence) === 'object') {
          presence = this.getPresenceDefinition(presence);
        }

        if (presence && presence.languageLabels && presence.systemPresence !== 'IDLE') {
          // en-us => en_US
          var locale = (0, _intl.formatPresenceLanguageLabelLocale)(this.get('intl.locale.0'));
          var languageLabel = Ember.get(presence.languageLabels, locale);

          if (this.get('acdDivisionPresenceProfileToggle') && !languageLabel) {
            languageLabel = Ember.get(presence.languageLabels, locale.toLowerCase());
          }

          if (fallback === undefined) {
            fallback = this.getPresenceFallback(presence.systemPresence);
          }

          return languageLabel || this.localizePresenceString(fallback);
        }
      }

      return this.localizePresenceString(fallback);
    },
    removeDeletedPresence: function removeDeletedPresence(presence) {
      this.get('presenceDefinitions').removeObject(presence);
    },
    findPresence: function findPresence(id) {
      var _this5 = this;

      var presenceDef = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!id) {
        return Ember.RSVP.resolve({});
      }

      var currentPresence = this.get('presenceDefinitions').find(function (presence) {
        return presence.id === id;
      });

      if (currentPresence) {
        var instance = _presence.default.create({
          presence: currentPresence
        }, Ember.getOwner(this).ownerInjection());

        return Ember.RSVP.resolve(instance);
      }

      var url = this.get('application').pcV2Uri(['api/v2/presencedefinitions', id]);
      return this.get('ajax').request(url).then(function (presence) {
        var model = _presence.default.create({
          presence: presence
        }, Ember.getOwner(_this5).ownerInjection());

        _this5.get('presenceDefinitions').addObject(presence);

        return model;
      }).catch(function (error) {
        if (_this5.get('session.features.acdDivisionPresenceProfileToggle')) {
          if (id && presenceDef && presenceDef.systemPresence) {
            // We need to map the out of band presence to a primary presence for translation sake.
            return _this5.get('primaryPresences').find(function (presence) {
              var matchingPrimaryPresence = presence.get('presence.systemPresence').toLowerCase() === presenceDef.systemPresence.toLowerCase();

              if (matchingPrimaryPresence) {
                var newPresence = presence.get('presence'); // Replace the id of the actual def instead of the primary presences id

                Ember.set(newPresence, 'id', presenceDef.id);

                var _instance = _presence.default.create({
                  presence: newPresence
                }, Ember.getOwner(_this5).ownerInjection());

                _this5.get('outOfBandPresenceDefinitions').addObject(_instance);

                return _instance;
              }

              return false;
            });
          }
        }

        _this5.get('logger').error('Presence: Couldnt find given presence', {
          error: error,
          remoteData: {
            presenceId: id
          }
        });
      });
    },
    selectPresence: function selectPresence(presenceModel) {
      this.get('presenceSubscription').changePresence(presenceModel.get('presence'));
    },
    setUsersPresence: function setUsersPresence(entity, presence) {
      if (typeof presence === 'string') {
        // Need to convert string lookups to actual internal models
        presence = this.getPresence(presence);
      }

      (0, _newrelicLegacy.addPageAction)('emberPurecloudComponents.entity.changeStatus', {
        presence: Ember.get(presence, 'presence.systemPresence')
      });
      var url = this.get('application').pcV2Uri(['api/v2/users', Ember.get(entity, 'id'), 'presences/PURECLOUD']);
      return this.get('ajax').patch(url, {
        contentType: 'application/json',
        data: {
          presenceDefinition: {
            id: Ember.get(presence, 'id')
          }
        }
      });
    },
    setUsersStatus: function setUsersStatus(entity, status) {
      var url = this.get('application').pcV2Uri(['api/v2/users', Ember.get(entity, 'id'), 'presences/PURECLOUD']);
      return this.get('ajax').patch(url, {
        contentType: 'application/json',
        data: {
          message: status
        }
      });
    },
    logoffUser: function logoffUser(entity) {
      var _this6 = this;

      var tokensUrl = this.get('application').pcV2Uri(['api/v2/apps/users', Ember.get(entity, 'id'), 'logout']);
      return this.get('ajax').del(tokensUrl, {
        dataType: 'text'
      }).then(function () {
        _this6.get('notification').info(null, _this6.get('intl').t('purecloud-components.logOffNotification'));
      }).catch(function (error) {
        _this6.get('logger').error('Presence: Unable to logout user.', {
          entity: entity,
          error: error
        });
      }).finally(function () {
        (0, _newrelicLegacy.addPageAction)('emberPurecloudComponents.entity.logoff');
      });
    },
    disassociateUserFromStation: function disassociateUserFromStation(entity) {
      var _this7 = this;

      var stationUrl = this.get('application').pcV2Uri(['api/v2/users', Ember.get(entity, 'id'), 'station/associatedstation']);
      return this.get('ajax').del(stationUrl).then(function () {
        _this7.get('notification').info(null, _this7.get('intl').t('purecloud-components.stationDisassociateNotification'));
      }).catch(function (error) {
        _this7.get('logger').error('Presence: Unable to dissociate user station.', {
          entity: entity,
          error: error
        });
      }).finally(function () {
        (0, _newrelicLegacy.addPageAction)('emberPurecloudComponents.entity.stationDisassociate');
      });
    }
  });

  _exports.default = _default;
});