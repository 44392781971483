define("ember-webrtc-components/mixins/webrtc-service/webrtc-stats", ["exports", "ember-webrtc-components/utils/hosted-context", "ember-purecloud-components/utils/newrelic-legacy", "ember-purecloud-components/utils/dates", "ember-webrtc-components/utils/webrtc-conversation-types"], function (_exports, _hostedContext, _newrelicLegacy, _dates, _webrtcConversationTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var Mixin = Ember.Mixin;

  var _default = Mixin.create({
    translateCandidatePairDetails: function translateCandidatePairDetails(data) {
      var candidateType = data.oCandidatePairDetails.local.candidateType;

      if (candidateType === 'relay') {
        var turnServerAddress = data.candidatePairDetails.local.ipAddress;
        data.candidatePairDetails.remote.ipAddress = turnServerAddress;
      }

      this.logger.debug('Translated localIp', {
        old: data.oCandidatePairDetails.local,
        new: data.candidatePairDetails.local
      });
    },
    _onStats: function _onStats(session, data) {
      if (data.name === 'connect') {
        if (!data.devices) {
          data.devices = {
            selected: {
              microphone: this.get('defaultMicrophone.label'),
              camera: this.get('defaultCamera.label')
            },
            all: {
              microphones: this.get('microphoneList').mapBy('label'),
              cameras: this.get('cameraList').mapBy('label')
            }
          };
        }

        var webrtcMediaDscp = this.get('phone.station.webrtcMediaDscp');
        var conversationType = this.get("sessionConversationMap.".concat(session.sid, ".conversationType"));

        if (webrtcMediaDscp && conversationType === 'softphone') {
          data.webrtcMediaDscp = webrtcMediaDscp;
        }

        try {
          var local = data.candidatePairDetails.local;
          var remote = data.candidatePairDetails.remote;
          local.ipAddress = local.ipAddress || local.ip || local.address;
          local.portNumber = local.portNumber || local.port;
          remote.ipAddress = remote.ipAddress || remote.ip || remote.address;
          remote.portNumber = remote.portNumber || remote.port;
        } catch (e) {
          this.logger.debug('Error copying connection information to old ipAddress/portNumber fields', e);
        }

        data.oCandidatePairDetails = data.candidatePairDetails;
        data.candidatePairDetails = JSON.parse(JSON.stringify(data.oCandidatePairDetails));
        data.session = session.sid; // only do crazy translation bit if we are in hosted context and it's not a host candidate already

        if (data.oCandidatePairDetails.local.candidateType !== 'host' && this.get('application.hostedContext').isHosted()) {
          this.translateCandidatePairDetails(data, session);
        }

        this.logger.debug('sending webrtc stats to hostedContext', data);
        this.get('application.hostedContext').sendEventToDesktop(_hostedContext.EVENTS.WEBRTC_CONNECTION_STARTED, data);
      }

      this.logger.debug('webrtcStats', data);
    },
    gatherStatsForSession: function gatherStatsForSession(session) {
      var _this = this;

      session.on('stats', function (data) {
        Ember.run(function () {
          _this._onStats(session, data);
        });
      });
    },
    _onTrace: function _onTrace(session, data) {
      var conversation = this.get("sessionConversationMap.".concat(session.sid));

      if (!conversation) {
        this.logger.debug('Could not find matching conversation for session', {
          remoteData: {
            sid: session.sid
          }
        });
        return;
      }

      data.conversationId = conversation.get('conversationId') || session.callInfo && session.callInfo.conversationId;
      data.conversationType = conversation.get('conversationType') || 'unknown';
      (0, _newrelicLegacy.addPageAction)('webrtcStats', this.flattenWebrtcObject(data, conversation));
      this.logger.warn('webrtcTraces', {
        webrtcTraces: data
      });
    },
    flattenWebrtcObject: function flattenWebrtcObject(data, conversation) {
      if (!data) {
        this.logger.info('Cannot flatten undefined object');
        return data;
      }

      var conference = data.conference && data.conference.toString();
      var conversationType = data.conversationType || conversation && conversation.get('conversationType');

      if (conversationType === _webrtcConversationTypes.default.SOFTPHONE) {
        conference = '<value hidden>';
      }

      var flatData = {
        webrtcEventName: data.name,
        localAudioMuted: conversation && conversation.get('localParticipant.audioMuted'),
        localVideoMuted: conversation && conversation.get('localParticipant.videoMuted'),
        interruptions: conversation && conversation.get('interruptions')
      };
      Object.keys(data).forEach(function (key) {
        var value = data[key];

        if (value === null || _typeof(value) !== 'object') {
          flatData[key] = value;
        }
      });
      flatData.conference = conference;

      if (data.name === 'getStats') {
        var audioTrack = data.tracks && data.tracks.find(function (t) {
          return t.kind === 'audio';
        });
        var videoTrack = data.tracks && data.tracks.find(function (t) {
          return t.kind === 'video';
        });
        var remoteTracks = data.remoteTracks || [];
        var remoteAudioBitrateAverage = 0;
        var remoteVideoBitrateAverage = 0;
        var remoteAudioLossAverage = 0;
        var remoteVideoLossAverage = 0;
        var remoteAudioLostTotal = 0;
        var remoteVideoLostTotal = 0;

        if (remoteTracks.length) {
          var remoteAudioTracks = remoteTracks.filter(function (t) {
            return t.kind === 'audio';
          });
          var remoteVideoTracks = remoteTracks.filter(function (t) {
            return t.kind === 'video';
          });
          var remoteAudioTrackCount = remoteAudioTracks.length;
          var remoteVideoTrackCount = remoteVideoTracks.length;

          var sum = function sum(a, b) {
            return a + b;
          };

          if (remoteAudioTracks.length) {
            remoteAudioBitrateAverage = remoteAudioTracks.map(function (t) {
              return t.bitrate;
            }).reduce(sum) / remoteAudioTrackCount;
            remoteAudioLossAverage = remoteAudioTracks.map(function (t) {
              return t.loss;
            }).reduce(sum) / remoteAudioTrackCount;
            remoteAudioLostTotal = remoteAudioTracks.map(function (t) {
              return t.lost;
            }).reduce(sum);
          }

          if (remoteVideoTracks.length) {
            remoteVideoBitrateAverage = remoteVideoTracks.map(function (t) {
              return t.bitrate;
            }).reduce(sum) / remoteVideoTrackCount;
            remoteVideoLossAverage = remoteVideoTracks.map(function (t) {
              return t.loss;
            }).reduce(sum) / remoteVideoTrackCount;
            remoteVideoLostTotal = remoteVideoTracks.map(function (t) {
              return t.lost;
            }).reduce(sum);
          }
        }

        Ember.assign(flatData, {
          remoteAudioBitrateAverage: remoteAudioBitrateAverage,
          remoteAudioLossAverage: remoteAudioLossAverage,
          remoteAudioLostTotal: remoteAudioLostTotal,
          remoteVideoBitrateAverage: remoteVideoBitrateAverage,
          remoteVideoLossAverage: remoteVideoLossAverage,
          remoteVideoLostTotal: remoteVideoLostTotal,
          remoteTracks: remoteTracks && remoteTracks.length
        });

        if (audioTrack) {
          Object.keys(audioTrack).forEach(function (key) {
            var value = audioTrack[key];

            if (value === null || _typeof(value) !== 'object') {
              flatData["localAudio".concat(Ember.String.capitalize(key))] = value;
            }
          });
        }

        if (videoTrack) {
          Object.keys(videoTrack).forEach(function (key) {
            var value = videoTrack[key];

            if (value === null || _typeof(value) !== 'object') {
              flatData["localVideo".concat(Ember.String.capitalize(key))] = value;
            }
          });
        }
      } else if (data.name === 'trace') {
        var lastEvent = data.traces[data.traces.length - 1];
        Ember.assign(flatData, {
          lastEventTime: lastEvent && lastEvent.time,
          lastEventValue: lastEvent && JSON.stringify(lastEvent.value),
          lastEventKind: lastEvent && lastEvent.kind
        });
      } else if (data.name === 'sessionEnded') {
        flatData.reason = data.reason && data.reason.condition ? data.reason.condition : data.reason;
      }

      return flatData;
    },
    _logConnectionEnded: function _logConnectionEnded(_ref) {
      var session = _ref.session,
          conversation = _ref.conversation,
          reason = _ref.reason;
      var duration = (0, _dates.default)().diff(conversation.get('conversationStart'), 'milliseconds');
      var data = {
        conversationId: conversation.get('conversationId') || 'unknown',
        conversationType: conversation.get('conversationType') || 'unknown',
        conversationDuration: duration,
        session: session.sid,
        initiator: session.isInitiator,
        conference: session.peerJid,
        reason: reason,
        name: 'sessionEnded'
      };
      (0, _newrelicLegacy.addPageAction)('webrtcStats', this.flattenWebrtcObject(data, conversation));
      return data;
    }
  });

  _exports.default = _default;
});