define("ember-webrtc-components/mixins/webrtc-service/webrtc-events", ["exports", "ember-purecloud-components/mixins/logger", "ember-webrtc-components/utils/webrtc-conversation-types", "ember-purecloud-components/services/streaming", "web-directory/utils/uuid", "ember-purecloud-components/utils/jid-helpers"], function (_exports, _logger, _webrtcConversationTypes, _streaming, _uuid, _jidHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Mixin = Ember.Mixin,
      RSVP = Ember.RSVP,
      inject = Ember.inject;
  var streamingEvent = _streaming.default.streamingEvent;

  var _default = Mixin.create(_logger.default, {
    chat: inject.service(),
    session: inject.service(),
    streaming: inject.service(),
    webrtcSdk: inject.service(),
    shouldIgnoreEvent: function shouldIgnoreEvent(eventName, fromJid, sessionId) {
      // volt one to one video
      if (fromJid.startsWith('peer-')) {
        this.logger.info('Ignoring jids that start with `peer-`', {
          remoteData: {
            fromJid: fromJid
          }
        });
        return true;
      } // ignore softphone events if we should be using the sdk built into web dir


      if ((0, _jidHelpers.isSoftphoneJid)(fromJid) && this.get('webrtcSdk.useSdk')) {
        this.logger.info('ewc: useSdk is enabled', {
          remoteData: {
            eventName: eventName
          }
        });
        return true;
      }

      if ((0, _jidHelpers.isScreenRecordingJid)(fromJid)) {
        this.logger.info('Ignoring screen recording event', {
          remoteData: {
            eventName: eventName
          }
        });
        return true;
      }

      if (!(0, _jidHelpers.isAcdJid)(fromJid) && !(0, _jidHelpers.isSoftphoneJid)(fromJid)) {
        this.logger.info('Ignoring event because it is not ACD or Softphone session', {
          remoteData: {
            fromJid: fromJid
          }
        });
        return true;
      }

      if (this.get('disableSoftphone') && this.getConversationTypeByJid(fromJid) === _webrtcConversationTypes.default.SOFTPHONE) {
        this.logger.info("Running with 'disableSoftphone', ignoring event: '".concat(eventName, "' for session: ").concat(sessionId));
        return true;
      }
    },
    onV2Connected: streamingEvent('*', 'connected', function () {
      var _this = this;

      this.refreshIceServers().then(function (iceServers) {
        return _this.logger.debug('initial iceservers', iceServers);
      }).catch(function (e) {
        return _this.logger.error('Failed to fetch initial ice servers', {
          remoteData: {
            error: e
          }
        });
      });
    }),
    // mediaChange is a realtime only event
    mediaChange: streamingEvent(function (mediaData) {
      var _this2 = this;

      var room = mediaData.room,
          from = mediaData.from,
          mediaInfo = mediaData.mediaInfo;
      this.get('chat').loadRoomAndUser(room, from).then(function (info) {
        var room = info.room,
            person = info.person;
        var hasMedia = mediaInfo.find(function (media) {
          return media.audio || media.video || media.listener;
        });

        if (!hasMedia) {
          person.set('mediaPresence', null);
          return;
        }

        if (mediaInfo[0].listener) {
          mediaInfo[0].msid = "listener_".concat(person.get('id'));
        }

        var existingMedia = person.get('mediaPresence');
        var mediaPresence = {
          roomJid: room.get('jid'),
          media: mediaInfo
        };
        var hasMsid = mediaInfo.find(function (media) {
          return media.msid;
        });
        var hasExistingMsid = existingMedia && existingMedia.media.find(function (media) {
          return media.msid;
        });

        if (JSON.stringify(existingMedia) !== JSON.stringify(mediaPresence)) {
          person.set('mediaPresence', mediaPresence);

          var conversation = _this2.getConversation({
            jid: room.get('jid')
          });

          if (mediaInfo[0].listener && conversation && conversation.get('isActive')) {
            conversation.checkForListeners();
          }

          _this2.get('streaming.connection').setActive(room.get('jid')); // we can't actually connect unless they have an msid, so otherwise don't alert yet


          if (hasMsid && !hasExistingMsid && person !== _this2.get('session.person')) {
            _this2.sendVideoInvitation(room, person);
          }
        }

        _this2.trigger('mediaChange', {
          personId: person.get('id'),
          media: mediaInfo
        });
      });
    }),
    realtimeRequestReconnect: streamingEvent(null, 'requestReconnect', function (cbFn) {
      this.requestReconnect(cbFn);
    }),
    hawkRequestReconnect: streamingEvent('*', 'requestReconnect', function (cbFn) {
      this.requestReconnect(cbFn);
    }),
    requestReconnect: function requestReconnect(cbFn) {
      if (this.get('hasActiveOrPendingSessions')) {
        cbFn({
          pending: true
        });
        return this.set('requestReconnectCallback', function () {
          return cbFn({
            done: true
          });
        });
      }

      cbFn({
        done: true
      });
    },
    // realtime only event
    requestWebrtcDump: streamingEvent(function (requestId) {
      var _this3 = this;

      return this.debugWebrtc({
        json: true
      }).then(function (snapshot) {
        snapshot.requestId = requestId;

        _this3.logger.info(snapshot);
      });
    }),
    // realtime only event
    upgradeMediaError: streamingEvent(function (error) {
      this.set('activeRoom.showingVideo', false);
      this.get('notification').error(null, this.get('intl').t('ewc.bridgeDownError'));
      this.logger.error('Videobridge unavailable', {
        kind: 'webrtcEvent',
        error: error.stack
      });
      this.trigger('upgradeMediaError');
    }),
    realtimeOnCancel: streamingEvent(null, 'cancelIncomingRtcSession', function (sessionId) {
      this.logger.debug('webrtcEvent realtimeOnCancel');
      this.onCancel(sessionId);
    }),
    hawkOnCancel: streamingEvent('webrtcSessions', 'cancelIncomingRtcSession', function (sessionId) {
      var pending = this.get('pendingSessions').findBy('id', sessionId);
      this.logger.debug('webrtcEvent hawkOnCancel', {
        remoteData: {
          sid: sessionId,
          conversationId: pending ? pending.conversationId : null
        }
      });
      this.onCancel(sessionId);
    }),
    onCancel: function onCancel(sessionId) {
      this.handleMissedCall(sessionId);
      this.cancelInvitationBySession(sessionId);
    },
    realtimeOnHandled: streamingEvent(null, 'handledIncomingRtcSession', function (sessionId) {
      this.logger.debug('webrtcEvent realtimeOnHandled');
      var pending = this.get('pendingSessions').findBy('id', sessionId);

      if (pending) {
        if (pending.conversationType && this.shouldUseHawkByConversationType(pending.conversationType)) {
          return;
        }

        if (pending.callInfo && this.shouldUseHawkByConversationType(_webrtcConversationTypes.default.SOFTPHONE)) {
          return;
        }

        if (pending.roomJid && this.shouldUseHawkByJid(pending.roomJid)) {
          return;
        }
      }

      this.onHandled(sessionId);
    }),
    hawkOnHandled: streamingEvent('webrtcSessions', 'handledIncomingRtcSession', function (sessionId) {
      var pending = this.get('pendingSessions').findBy('id', sessionId);

      if (pending) {
        this.logger.debug('webrtcEvent hawkOnHandled', {
          remoteData: {
            sid: sessionId,
            conversationId: pending.conversationId
          }
        });

        if (pending.conversationType && !this.shouldUseHawkByConversationType(pending.conversationType)) {
          return;
        }

        if (pending.callInfo && !this.shouldUseHawkByConversationType(_webrtcConversationTypes.default.SOFTPHONE)) {
          return;
        }

        if (pending.roomJid && !this.shouldUseHawkByJid(pending.roomJid)) {
          return;
        }
      }

      this.onHandled(sessionId);
    }),
    onHandled: function onHandled(sessionId) {
      this.cancelInvitationBySession(sessionId);
    },
    _realtimeOnPropose: streamingEvent(null, 'requestIncomingRtcSession', function (sessionInfo) {
      this.logger.info('Incoming propose from realtime', {
        remoteData: {
          sid: sessionInfo.sessionId
        }
      });

      var jid = this._getSimpleJid(sessionInfo.fromJid);

      if (!this.shouldUseHawkByJid(jid)) {
        this._onPropose(sessionInfo);

        var webrtcSessions = this.get('webrtcSessions');

        if (webrtcSessions) {
          webrtcSessions.rejectRtcSession(sessionInfo.sessionId, true); // tell hawk to ignore this session entirely
        }
      } else {
        this.get('streaming.connection').rejectRtcSession(sessionInfo.sessionId, true); // tell realtime to ignore this session entirely
      }
    }),
    _hawkOnPropose: streamingEvent('webrtcSessions', 'requestIncomingRtcSession', function (sessionInfo) {
      this.logger.info('Incoming propose from hawk', {
        remoteData: {
          sid: sessionInfo.sessionId,
          conversationId: sessionInfo.conversationId
        }
      });

      var jid = this._getSimpleJid(sessionInfo.fromJid);

      if (this.shouldUseHawkByJid(jid)) {
        this._onPropose(sessionInfo);
      } else {
        var webrtcSessions = this.get('webrtcSessions');

        if (webrtcSessions) {
          webrtcSessions.rejectRtcSession(sessionInfo.sessionId, true); // tell hawk to ignore this session entirely
        }
      }
    }),
    _onPropose: function _onPropose(sessionInfo) {
      var _this4 = this;

      var roomJid = sessionInfo.roomJid,
          fromJid = sessionInfo.fromJid,
          conversationId = sessionInfo.conversationId,
          sessionId = sessionInfo.sessionId,
          autoAnswer = sessionInfo.autoAnswer;

      if (this.shouldIgnoreEvent('propose', fromJid, sessionId)) {
        return;
      }

      this.logger.info('handling propose', {
        remoteData: {
          sessionId: sessionId,
          conversationId: conversationId,
          autoAnswer: autoAnswer
        }
      });
      var isRequestFromGjoll = fromJid.match(/@gjoll/);

      if (!conversationId && isRequestFromGjoll && sessionId) {
        if (!this.get('webrtc.phone.station.persistentConnection')) {
          this.logger.warn('Received rtc session from gjoll without a conversationId when not configured for persistent connection', {
            remoteData: {
              sessionId: sessionId,
              conversationId: conversationId
            }
          });
        }

        var tmpConversationId = _uuid.default.generate();

        this.logger.info('propose has no conversationId but is from the gateway, assuming request for persistent connection', {
          remoteData: {
            sessionId: sessionId,
            tmpConversationId: tmpConversationId
          }
        });
        this.createPendingSession(null, {
          id: sessionId,
          callInfo: {
            conversationId: tmpConversationId
          }
        });
        return this.answerSoftphoneConversation(tmpConversationId);
      }

      if (conversationId && isRequestFromGjoll && sessionId) {
        return this._handleIncomingWebrtcSoftphoneRequest(sessionInfo);
      }

      if (!(0, _jidHelpers.isSoftphoneJid)(roomJid)) {
        this._handleIncomingVideoSessionRequest(sessionInfo, roomJid);
      }

      if ((0, _jidHelpers.isScreenRecordingJid)(roomJid) && this.canDoScreenRecording()) {
        this.logger.info('sending proceed', {
          remoteData: {
            sessionId: sessionId,
            conversationId: conversationId
          }
        });
        return this.get('webrtcSessions').acceptRtcSession(sessionId).then(function () {
          _this4.logger.info('proceed sent', {
            remoteData: {
              sessionId: sessionId,
              conversationId: conversationId
            }
          });
        });
      }
    },
    hawkIncomingSession: streamingEvent('webrtcSessions', 'incomingRtcSession', function (session) {
      this.logger.info('Incoming session from hawk', {
        remoteData: {
          sid: session.sid,
          conversationId: session.conversationId
        }
      });

      var jid = this._getSimpleJid(session.peerID);

      if (this.shouldUseHawkByJid(jid)) {
        this.handleIncomingSession(session);
        this.get('webrtcSessions').rtcSessionAccepted(session.sid);
      } else {
        session.end('alternative-session', true); // end silently

        var webrtcSessions = this.get('webrtcSessions');

        if (webrtcSessions) {
          webrtcSessions.rejectRtcSession(session.sid, true); // tell hawk to ignore this session entirely
        }
      }
    }),
    realtimeIncomingSession: streamingEvent(null, 'incomingRtcSession', function (session) {
      this.logger.info('Incoming session from realtime', {
        remoteData: {
          sid: session.sid
        }
      });

      var jid = this._getSimpleJid(session.peerID);

      if (!this.shouldUseHawkByJid(jid)) {
        this.handleIncomingSession(session);
      } else {
        session.end('alternative-session', true); // end silently

        this.get('streaming.connection').rejectRtcSession(session.sid, true); // tell realtime to ignore this session entirely
      }
    }),
    handleIncomingSession: function handleIncomingSession(session) {
      var _this5 = this;

      var jid = this._getSimpleJid(session.peerID);

      if (this.shouldIgnoreEvent('session-init', jid, session.sid)) {
        return;
      }

      this.logger.info('Handling incoming session', {
        remoteData: {
          sid: session.sid,
          conversationId: session.conversationId
        }
      });
      var pendingSessions = this.get('pendingSessions');
      var pendingSession = pendingSessions.findBy('id', session.sid);
      var acceptImmediately = false;
      var conversationId = null;

      if ((0, _jidHelpers.isScreenRecordingJid)(jid) && pendingSessions.findBy('roomJid', jid)) {
        pendingSession = pendingSessions.findBy('roomJid', jid);
        pendingSession.timeoutTask.cancel();
        pendingSessions.removeObject(pendingSession);
        acceptImmediately = true;
      } else if (!pendingSession && pendingSessions.length === 1 && pendingSessions[0].requestForIncoming === true) {
        // we have an incoming session, but we also have a pending outgoing - this was our request to jitsi or
        // the edge for a webrtc session.
        // grab the session request call info and attach it to the new session
        session.callInfo = pendingSessions[0].callInfo;

        if (session.callInfo) {
          conversationId = pendingSessions[0].callInfo.conversationId;

          var _conversation = this.get('phone.conversations').findBy('id', conversationId);

          if (_conversation) {
            _conversation.set('webrtcSession', session);
          }
        }

        pendingSessions[0].timeoutTask.cancel();
        pendingSessions.removeAt(0);
        acceptImmediately = true;
      } else if (pendingSession && !pendingSession.isIncoming) {
        if (pendingSession.callInfo && pendingSession.callInfo.conversationId) {
          session.callInfo = pendingSession.callInfo;
          conversationId = pendingSession.callInfo.conversationId;

          var _conversation2 = this.get('phone.conversations').findBy('id', conversationId);

          if (_conversation2) {
            _conversation2.set('webrtcSession', session);

            _conversation2.set('conversationId', conversationId);
          }
        } else {
          this.logger.warn('Got an incoming session for a pending outgoing session - conflict warning');
        }

        acceptImmediately = true;
        pendingSession.timeoutTask.cancel();
      }

      if (pendingSession) {
        pendingSessions.removeObject(pendingSession);
      }

      if (conversationId) {
        this.logger.info('processing incoming session for conversation', {
          remoteData: {
            sid: session.sid,
            conversationId: conversationId
          }
        });
      }

      var conversation;
      var screenMetaData;
      return this.getOrCreateConversation({
        jid: jid,
        conversationId: conversationId,
        create: true
      }).then(function (c) {
        conversation = c;
        conversationId = conversationId || c.get('conversationId');

        if (conversation.get('conversationType') === _webrtcConversationTypes.default.ENGAGE_SCREENSHARE) {
          return;
        }

        if (conversation.get('conversationType') === _webrtcConversationTypes.default.ENGAGE_SCREEN_RECORDING) {
          // this is screenRecordingViaApi and we need create media now
          if (!pendingSession.stream) {
            return _this5.getScreenRecordingMedia(conversationId).then(function (_ref) {
              var stream = _ref.stream,
                  metas = _ref.metas;
              conversation.set('screenRecordingStream', stream);

              if (!metas.length) {
                _this5.logger.error('No metadata found for screen information', {
                  remoteData: {
                    conversationId: conversationId
                  }
                });

                return;
              }

              screenMetaData = metas;
            });
          }
        } else if (conversationId) {
          var existingMedia = pendingSession ? pendingSession.media || pendingSession.stream : null;
          var promise = RSVP.resolve(existingMedia);

          if (!conversation.get('conversationType')) {
            conversation.set('conversationType', _webrtcConversationTypes.default.SOFTPHONE);
          }

          if (!existingMedia) {
            _this5.logger.info('creating user media', {
              remoteData: {
                conversationId: conversationId
              }
            });

            promise = _this5.createMedia({
              requestVideo: false
            }).catch(function (err) {
              pendingSession.createMediaError = err;

              _this5.get('notification').error(null, _this5.get('intl').t('ewc.failedToStartMedia'));

              err.sessionId = session.sid;
              err.conversationId = conversationId;

              _this5.logger.error('error creating media', {
                remoteData: {
                  err: err,
                  conversationId: conversationId
                }
              });

              return RSVP.reject(err);
            }).then(function (m) {
              _this5.logger.info('media created', {
                remoteData: {
                  conversationId: conversationId
                }
              });

              return m;
            });
          }

          return promise.then(function (media) {
            conversation.addLocalParticipant();
            conversation.setLocalMedia(media);
          });
        }
      }).then(function () {
        return conversation.addSession(session, acceptImmediately || conversation.get('hasJoined')).then(function () {
          if (conversation.get('conversationType') === _webrtcConversationTypes.default.ENGAGE_SCREEN_RECORDING) {
            if (!_this5.get('screenRecordingViaApi')) {
              return;
            }

            var stream = conversation.get('screenRecordingStream');
            stream.getTracks().forEach(function (track) {
              var meta = screenMetaData.find(function (meta) {
                return meta.trackId === track.id;
              });
              var transceiver = conversation.getNativePeerConnection(session).getTransceivers().find(function (t) {
                return t.sender.track && t.sender.track.id === track.id;
              });

              if (!transceiver) {
                _this5.logger.error('Failed to find transceiver for screenTrack', {
                  remoteData: {
                    conversationId: conversation.get('conversationId'),
                    trackId: track.id
                  }
                });

                return;
              } // TODO: when public api has been fixed:
              // remove the _trackId property, don't modify the trackId property


              meta._trackId = meta.trackId;
              meta.mid = meta.trackId = transceiver.mid;

              _this5.restartScreenTrackOnFailure(conversation, track, meta.screenId, meta.mid, transceiver);
            });
            var data = JSON.stringify({
              participantJid: _this5.get('session.person.jid'),
              metaData: screenMetaData,
              roomId: conversation.get('jid'),
              conversationId: conversationId
            });
            return _this5.get('ajax').post("/platform/api/v2/recordings/screensessions/metadata", {
              data: data,
              dataType: 'text'
            });
          }
        });
      }).then(function () {
        if (pendingSession && !pendingSession.callInfo && !conversation.get('isScreenRecording')) {
          _this5.get('sidebar').openPanel('webrtc');
        }
      }).catch(function (err) {
        _this5.logger.error('Error from getOrCreateConversation when handling incoming session', {
          remoteData: {
            err: err,
            session: session.sid,
            conversationId: conversationId
          }
        });
      });
    },
    realtimeOutgoingRtcSession: streamingEvent(null, 'outgoingRtcSession', function (session) {
      if (!this.shouldUseHawkByJid(session.jid)) {
        this.outgoingRtcSession(session);
      }
    }),
    hawkOutgoingRtcSession: streamingEvent('webrtcSessions', 'outgoingRtcSession', function (session) {
      if (this.shouldUseHawkByJid(session.jid)) {
        this.outgoingRtcSession(session);
      }
    }),
    outgoingRtcSession: function outgoingRtcSession(session) {
      this.logger.info('outgoing rtc session');
      var pendingSessions = this.get('pendingSessions');
      var pendingSession = pendingSessions.findBy('id', session.sid);

      if (!pendingSession) {
        this.logger.error('Outgoing jingle session does not match any pending outgoing call');
        return;
      }

      pendingSessions.removeObject(pendingSession);

      var bareJid = this._getSimpleJid(session.peerID);

      var conversation = this.getConversation({
        jid: bareJid
      });
      conversation.addSession(session);
    },
    // realtime only for now
    outgoingRtcSessionRejected: streamingEvent(function (sessionId) {
      this.logger.info('outgoing rtc session rejected');
      var pendingSession = this.get('pendingSessions').findBy('id', sessionId);
      pendingSession.rejected = true;

      this._noAnswer(pendingSession);
    }),
    realtimeOutgoingRtcSessionProceed: streamingEvent(null, 'outgoingRtcSessionProceed', function (sessionId, targetJid) {
      var jid = this._getSimpleJid(targetJid);

      if (!this.shouldUseHawkByJid(jid)) {
        this.outgoingRtcSessionProceed(sessionId, targetJid);
      }
    }),
    hawkOutgoingRtcSessionProceed: streamingEvent('webrtcSessions', 'outgoingRtcSessionProceed', function (sessionId, targetJid) {
      var jid = this._getSimpleJid(targetJid);

      if (this.shouldUseHawkByJid(jid)) {
        this.outgoingRtcSessionProceed(sessionId, targetJid, true);
      }
    }),
    outgoingRtcSessionProceed: function outgoingRtcSessionProceed(sessionId, targetJid, useHawk) {
      this.logger.info('outgoing proceed');
      var pendingSession = this.get('pendingSessions').findBy('id', sessionId);

      if (!pendingSession) {
        this.logger.error('Got a proceed event for a nonexistant or incorrect session');
        return;
      }

      var timeout = pendingSession.timeoutTask;

      if (timeout) {
        timeout.cancel();
      }

      var peerConnectionConstraints = this.get('peerConnectionConstraints');

      if (useHawk) {
        this.get('webrtcSessions').createRtcSession({
          jid: targetJid,
          sid: sessionId,
          stream: pendingSession.stream,
          peerConstraints: pendingSession.peerConstraints,
          peerConnectionConstraints: peerConnectionConstraints
        });
      } else {
        this.get('streaming.connection').createRtcSession(targetJid, sessionId, pendingSession.stream, pendingSession.peerConstraints, peerConnectionConstraints);
      }
    },
    HawkRtcSessionError: streamingEvent('webrtcSessions', 'rtcSessionError', function () {
      this.rtcSessionError.apply(this, arguments);
    }),
    hawkTraceRtcSession: streamingEvent('webrtcSessions', 'traceRtcSession', function () {
      this.traceRtcSession.apply(this, arguments);
    }),
    traceRtcSession: streamingEvent(function (level, message, data) {
      var sid = '';
      var messageWithoutSession;

      if (typeof message === 'string') {
        var sessionRegex = /([a-f0-9-]{24,}|[a-f0-9]{16,}): (.+)/;
        var matches = sessionRegex.exec(message);

        if (matches) {
          sid = matches[1];
          messageWithoutSession = matches[2];
        } else {
          sid = data;
        }
      } else if (message.msg) {
        data = message;
        sid = data.sid;
        message = message.msg;
        messageWithoutSession = message;
        delete data.msg;
      } else {
        sid = data;
        messageWithoutSession = message;
      }

      var conversation = this.get("sessionConversationMap.".concat(sid));
      var conversationId;
      var conversationType;
      var jid;

      if (conversation) {
        conversationId = conversation.get('conversationId');
        conversationType = conversation.get('conversationType');
        jid = conversation.get('jid');
      } else {
        var pendingSessions = this.get('pendingSessions');

        if (pendingSessions.length === 1 && (pendingSessions[0].requestForIncoming || pendingSessions[0].id === sid) && pendingSessions[0].callInfo) {
          conversationId = pendingSessions[0].callInfo.conversationId;
          conversationType = 'softphone';
          jid = pendingSessions[0].callInfo.jid;
        }
      }

      if (!conversationId) {
        var conversations = this.get('conversations');
        conversation = conversations && conversations.find(function (c) {
          var knownSessions = c.get('knownSessions');
          return knownSessions && knownSessions.includes(sid);
        });

        if (conversation) {
          conversationId = conversation.get('conversationId');
          conversationType = conversation.get('conversationType');
          jid = conversation.get('jid');
        }
      }

      if (message === 'Unknown session') {
        level = 'debug';
      }

      if (messageWithoutSession === 'Discovered new ICE candidate') {
        level = 'debug'; // override down to debug so it doesn't go to sumo
      }

      if (messageWithoutSession === 'Processing ice candidate') {
        // stringified ice candidate log so that selenium can log it
        this.logger[level]("".concat(messageWithoutSession, ": ").concat(data));
        return;
      }

      this.logger[level](messageWithoutSession, {
        kind: 'RtcSession Log',
        jid: jid,
        conversationId: conversationId,
        conversationType: conversationType,
        sid: sid,
        data: data
      });
    }),
    hawkOnIceServers: streamingEvent('webrtcSessions', 'rtcIceServers', function (iceServers) {
      this.logger.debug('streaming-client ice servers', iceServers);
      this.set('iceServers', iceServers);
      this.probeNetwork();
    }),
    // TODO: ft streaming service
    rtcSessionError: streamingEvent(function (message) {
      var error;

      if (typeof message === 'string') {
        if (message.toLowerCase().indexOf('cannot reject') === 0) {
          return; // suppress this error during realtime/hawk toggle crossover period
        }

        error = new Error(message);
      } else {
        error = {
          kind: 'rtcSessionError',
          message: message
        };
      }

      this.logger.error(error.message, {
        remoteData: {
          error: error
        }
      });
    }),
    // TODO: remove when v1 is deprecated
    videoJoin: streamingEvent(function (videoInfo) {
      var _this6 = this;

      var from = videoInfo.from,
          room = videoInfo.room;

      if ((0, _jidHelpers.compareJids)(from, this.get('session.person.jid'))) {
        return; // don't notify about your own join event
      }

      this.get('chat').loadRoomAndUser(room, from).then(function (info) {
        var room = info.room,
            person = info.person;

        _this6.get('streaming.connection').setActive(room.get('jid'));

        _this6.sendVideoInvitation(room, person);
      });
    }),
    // TODO: remove when v1 is deprecated
    videoLeave: streamingEvent(function (videoInfo) {
      var _this7 = this;

      var room = videoInfo.room,
          from = videoInfo.from;

      if ((0, _jidHelpers.compareJids)(from, this.get('session.person.jid'))) {
        return; // don't notify about your own leave event
      }

      this.get('chat').loadRoomAndUser(room, from).then(function (info) {
        var room = info.room,
            person = info.person;

        _this7.get('streaming.connection').setActive(room.get('jid'));

        _this7.cancelInvitation(room, person);
      });
    })
  });

  _exports.default = _default;
});